import * as React from 'react';
import PropTypes from 'prop-types';

interface IContainerProps {
    children: React.ReactNode;
}

const Container = React.forwardRef((props: IContainerProps, ref: React.Ref<any>) => {
    return (
        <div ref={ref} className="app-container w-11/12 h-full mx-auto p-0 md:p-8 pt-36 md:pt-48 mb-20">
            {props.children}
        </div>
    );
});

Container.displayName = 'Container';

Container.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Container;

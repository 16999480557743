import logo_memo from './assets/img/new_logo_memo.png';
import logo_memo_dark from './assets/img/logo_memo_dark.png';
import company_logo from './assets/img/logo_globe.png';

const adminTheme = {
    appHeader: {
        branding: {
            logo: logo_memo_dark, //logo_memo
        },
        backgroundColor: 'bg-blue-darker', // bg-blue
        fontColor: 'text-white', // text-normal
        company: {
            logo: company_logo,
        },
    },
    navigation: {
        active: {
            backgroundColor: 'bg-yellow-light', // bg-blue
            borderColor: '#E7BA64', // #5492C6
        },
    },
    buttons: {
        primary: {
            default: {
                backgroundColor: 'bg-yellow-light', // bg-blue
                fontColor: 'text-white', // text-white
            },
            dark: {
                backgroundColor: 'bg-blue-dark',
                fontColor: 'text-white',
            },
        },
        secondary: {
            default: {
                borderColor: '#E7BA64', //#5492C6'
                fontColor: 'text-yellow-light', // text-blue
            },
            dark: {
                borderColor: '#5492C6',
                fontColor: 'text-blue-dark',
            },
        },
        danger: {
            backgroundColor: '',
            fontColor: 'text-red',
        },
        inverse: {
            default: {
                backgroundColor: 'bg-white',
                fontColor: 'text-blue-dark',
            },
            outline: {
                backgroundColor: 'bg-blue-dark',
                fontColor: 'text-white',
            },
        },
        navbar: {
            backgroundColor: 'bg-blue-light',
            fontColor: 'text-blue-dark',
        },
    },
    tabs: {
        active: {
            borderColor: '#E7BA64', //#E7BA64
        },
    },
    table: {
        header: {
            background: 'bg-blue-darker', //bg-gray-dark
        },
    },
    toggle: {
        active: {
            background: 'bg-yellow-light',
        },
    },
    stepper: {
        active: {
            background: 'bg-yellow-light',
        },
    },
};

const basicTheme = {
    appHeader: {
        branding: {
            logo: logo_memo,
        },
        backgroundColor: 'bg-white',
        fontColor: 'text-normal',
        company: {
            logo: company_logo,
        },
    },
    navigation: {
        active: {
            backgroundColor: 'bg-blue',
            borderColor: '#5492C6',
        },
    },
    buttons: {
        primary: {
            default: {
                backgroundColor: 'bg-blue',
                fontColor: 'text-white',
            },
            dark: {
                backgroundColor: 'bg-blue-dark',
                fontColor: 'text-white',
            },
        },
        secondary: {
            default: {
                borderColor: '#5492C6',
                fontColor: 'text-blue',
            },
            dark: {
                borderColor: '#5492C6',
                fontColor: 'text-blue-dark',
            },
        },
        danger: {
            backgroundColor: '',
            fontColor: 'text-red',
        },
        inverse: {
            default: {
                backgroundColor: 'bg-white',
                fontColor: 'text-blue-dark',
            },
            outline: {
                backgroundColor: 'bg-blue-dark',
                fontColor: 'text-white',
            },
        },
        navbar: {
            backgroundColor: 'bg-blue-light',
            fontColor: 'text-blue-dark',
        },
    },
    tabs: {
        active: {
            borderColor: '#1352AB',
        },
    },
    table: {
        header: {
            background: 'bg-gray-dark',
        },
    },
    toggle: {
        active: {
            background: 'bg-blue-dark',
        },
    },
    stepper: {
        active: {
            background: 'bg-blue-dark',
        },
    },
};

export const flavor = {
    defaultPath: process.env.REACT_APP_BUILD_TYPE === 'admin' ? '/categories' : '/dashboard',
    buildType: process.env.REACT_APP_BUILD_TYPE, // (basic | admin) - Separates admin and basic features on index.tsx.
    theme: basicTheme, // (basicTheme | adminTheme) - Separates admin and basic theme across the app.
};

import React from 'react';
import { flavor } from '../../AppFlavor';

export const appHeaderStyles: React.CSSProperties = {
    height: '80px',
    boxShadow: '0px 10px 16px #0000003C',
};

export const appHeaderPDFStyles: React.CSSProperties = {
    height: '80px',
};

interface IAppHeaderProps {
    isPDFView?: boolean;
    children?: React.ReactNode;
}

function AppHeader(props: IAppHeaderProps) {
    if (props.isPDFView) {
        return (
            <div
                className={`${flavor.theme.appHeader.backgroundColor} items-center px-2 flex justify-between relative z-50`}
                style={appHeaderPDFStyles}
                data-testid="app-header-pdf"
            >
                {props.children}
            </div>
        );
    }
    return (
        <div
            className={`${flavor.theme.appHeader.backgroundColor} items-center pl-4 flex justify-start md:justify-between relative z-50`}
            style={appHeaderStyles}
            data-testid="app-header"
        >
            {props.children}
        </div>
    );
}

export default AppHeader;

import { RouteComponentProps, useLocation, useMatch } from '@reach/router';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import menu from '../../../assets/img/menu.svg';
import menu_close from '../../../assets/img/menu_close.svg';
import AppHeader from '../../../layouts/app-header/AppHeader';
import Branding from '../../../layouts/navbar/branding/Branding';
import logo_globe from '../../../assets/img/logo_globe.png';

interface IProtectedRouteProps {
    path?: RouteComponentProps;
    default?: boolean;
    title?: string;
    // memo_id?: string;
    component: React.ComponentType;
}

const ProtectedRoute: React.FunctionComponent<IProtectedRouteProps> = ({ component: Component, ...rest }) => {
    const [toggled, setToggle] = React.useState(false);
    const location = useLocation();

    useEffect(() => {
        let page_title = 'Memo App (Beta)';

        if (rest.title) {
            page_title = rest.title;

            firebase.analytics().setCurrentScreen(location.pathname);
            firebase.analytics().logEvent('page_view', { page_path: location.pathname, page_title: page_title });
        }

        document.title = page_title;
    }, []);

    const toggleMenu = () => {
        setToggle(!toggled);
    };

    return (
        <div className="w-full h-auto">
            <div style={{ zIndex: 99999 }} className="w-full h-auto fixed">
                <AppHeader>
                    <button className="visible md:hidden p-2" onClick={toggleMenu}>
                        <img width="23px" src={toggled ? menu_close : menu} alt="menu" />
                    </button>
                    <Branding />
                    <div className="mx-8">
                        {/* <img style={{ width: '25%' }} src={logo_globe} alt="Globe" /> */}
                        <img style={{ width: '127px' }} src={logo_globe} alt="Globe" />
                    </div>
                </AppHeader>
                <div
                    className={`w-full absolute transition-left delay-100 duration-300 ease-in-out ${
                        toggled ? 'left-0' : '-left-full md:left-0'
                    }`}
                >
                    <nav
                        style={{ zIndex: 9999 }}
                        className="bg-blue-dark inline-flex flex-col w-full h-screen md:h-full"
                        role="navigation"
                    >
                        <hr className="h-lg bg-transparent visible md:hidden border-none" />
                    </nav>
                </div>
                <hr className="h-lg bg-blue-dark visible md:hidden border-none" />
            </div>
            <Component {...rest} />
        </div>
    );
};
ProtectedRoute.propTypes = {
    component: PropTypes.any,
};

export default ProtectedRoute;

/**
 * AuthSlice is for auth managament. This includes action and reducer for auth data available in Firebase.
 */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../models/User';
import { UserState } from '../models/UserState';
import firebase from 'firebase/app';

const initialState: UserState = {
    user: {
        displayName: null,
        email: null,
        phoneNumber: null,
        photoURL: null,
        providerId: '',
        uid: '',
    },
    date_refreshed: '',
    setting: {
        httpState: 'IDLE',
        showDeprecationBanner: false,
        isMemoCreationAllowed: true,
    },
};

export const checkIfCreationAllowed = createAsyncThunk('user/checkIfCreationAllowed', async (email: string) => {
    const emailDomain = email.split('@')[1];

    const firestore = firebase.firestore();
    const groupDoc = await firestore.collection('user-groups').doc(emailDomain).get();

    if (!groupDoc.exists)
        return {
            showDeprecationBanner: false,
            isMemoCreationAllowed: true,
        };

    const group = groupDoc.data()!;

    return {
        showDeprecationBanner: group.status === 'RESTRICTED' || group.status === 'DISABLED',
        isMemoCreationAllowed: group.status !== 'DISABLED',
    };

    // const firestore = firebase.firestore();
    // const settingsDoc = await firestore.collection('settings').doc('default').get();

    // if (!settingsDoc.exists) return true;

    // const settings = settingsDoc.data();
    // const domains = settings!.disabled_domains as string[];
    // // const allowedUsers = settings!.allowedUsers as string[];
    // // if (allowedUsers.includes(email)) return true;

    // if (!domains || !domains.length) return true;

    // return !domains.includes(emailDomain);
});

const AuthSlice = createSlice({
    name: 'AuthSlice',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User>) {
            state.date_refreshed = new Date().toISOString();
            state.user = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(checkIfCreationAllowed.pending, (state) => {
                state.setting.httpState = 'PENDING';
            })
            .addCase(checkIfCreationAllowed.fulfilled, (state, action) => {
                state.setting.httpState = 'FULFILLED';
                state.setting.isMemoCreationAllowed = action.payload.isMemoCreationAllowed;
                state.setting.showDeprecationBanner = action.payload.showDeprecationBanner;
            })
            .addCase(checkIfCreationAllowed.rejected, (state) => {
                state.setting.httpState = 'REJECTED';
                state.setting.isMemoCreationAllowed = false;
            });
    },
});

export const { setUser } = AuthSlice.actions;

export default AuthSlice.reducer;

import * as React from 'react';
import { flavor } from '../../AppFlavor';
const { buttons } = flavor.theme;

export const buttonStyles: React.CSSProperties = {
    borderRadius: '5px',
    outline: 'none',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
};

export const buttonOutlineStyles: React.CSSProperties = {
    boxSizing: 'border-box',
    borderRadius: '5px',
    outline: 'none',
    boxShadow: `inset 0px 0px 0px 1px ${buttons.secondary.default.borderColor}`,
    display: 'flex',
    flexDirection: 'row',
};

export const iconStyles: React.CSSProperties = {
    width: '10px',
    height: '10px',
    marginRight: '8px',
};

export interface IButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    color?: string;
    icon?: string;
    iconAlt?: string;
    weight?: string;
    children: React.ReactNode;
}

const Button = (props: IButtonProps) => {
    const { color, disabled, icon, iconAlt, weight } = props;

    const DEFAULT_WIDTH = 'w-auto';
    const DEFAULT_FONT_SIZE = 'text-sm';
    const DEFAULT_FONT_WEIGHT = 'font-bold';
    const DEFAULT_PADDINGS = 'py-2 px-5';
    const DEFAULT_MARGINS = 'm-1';
    const DEFAULT_FLEX_STYLES = 'items-center justify-center';

    /** Primary Theme */
    const PRIMARY_FONT_COLOR = buttons.primary.default.fontColor;
    const PRIMARY_BG_COLOR = buttons.primary.default.backgroundColor;

    /** Primary Dark Theme */
    const PRIMARY_DARK_FONT_COLOR = buttons.primary.dark.fontColor;
    const PRIMARY_DARK_BG_COLOR = buttons.primary.dark.backgroundColor;

    /** Secondary Theme */
    const SECONDARY_FONT_COLOR = buttons.secondary.default.fontColor;

    /** Secondary Dark Theme*/
    const SECONDARY_DARK_FONT_COLOR = buttons.secondary.dark.fontColor;

    /** Danger Theme */
    const DANGER_FONT_COLOR = buttons.danger.fontColor;

    /** Inverse Theme */
    const INVERSE_FONT_COLOR = buttons.inverse.default.fontColor;
    const INVERSE_BG_COLOR = buttons.inverse.default.backgroundColor;

    /** Inverse Outline Theme */
    const INVERSE_OUTLINE_FONT_COLOR = buttons.inverse.outline.fontColor;
    const INVERSE_OUTLINE_BG_COLOR = buttons.inverse.outline.backgroundColor;

    /** Navbar Theme */
    const NAVBAR_FONT_COLOR = buttons.navbar.fontColor;
    const NAVBAR_BG_COLOR = buttons.navbar.backgroundColor;
    const NAVBAR_PADDINGS = 'py-2 px-7';

    /** Disabled Theme */
    const DISABLED_STYLES = 'opacity-50 cursor-not-allowed';

    const getButtonClassName = () => {
        const WEIGHT = weight ? weight : DEFAULT_FONT_WEIGHT;
        const DISABLED = disabled ? DISABLED_STYLES : '';

        switch (color) {
            case 'primary':
                return `${DEFAULT_WIDTH} ${PRIMARY_FONT_COLOR} ${PRIMARY_BG_COLOR} ${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE} ${PRIMARY_FONT_COLOR} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'primary-dark':
                return `${PRIMARY_DARK_BG_COLOR} ${PRIMARY_DARK_FONT_COLOR} ${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'secondary':
                return `${SECONDARY_FONT_COLOR} ${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE}  ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'secondary-dark':
                return `${SECONDARY_DARK_FONT_COLOR} ${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE} ${SECONDARY_DARK_FONT_COLOR} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'danger':
                return `${DANGER_FONT_COLOR} ${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'inverse':
                return `${DEFAULT_WIDTH} ${INVERSE_BG_COLOR} ${INVERSE_FONT_COLOR} ${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'inverse-outline':
                return `${DEFAULT_WIDTH} ${INVERSE_OUTLINE_FONT_COLOR} ${INVERSE_OUTLINE_BG_COLOR} ${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'navbar':
                return `${DEFAULT_WIDTH} ${NAVBAR_BG_COLOR} ${NAVBAR_FONT_COLOR} ${NAVBAR_PADDINGS} ${DEFAULT_FONT_SIZE} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            case 'navbar-disabled':
                return `${DEFAULT_WIDTH} bg-yellow-disabled ${NAVBAR_FONT_COLOR} ${NAVBAR_PADDINGS} ${DEFAULT_FONT_SIZE} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
            default:
                return `${DEFAULT_PADDINGS} ${DEFAULT_MARGINS} ${DEFAULT_FONT_SIZE} ${SECONDARY_FONT_COLOR} ${DEFAULT_FLEX_STYLES} ${WEIGHT} ${DISABLED}`;
        }
    };

    const getIcon = () => {
        return icon && <img style={iconStyles} src={icon} alt={iconAlt} />;
    };

    switch (color) {
        case 'primary':
            return (
                <button className={getButtonClassName()} style={buttonStyles} data-testid="btn-primary" {...props}>
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'primary-dark':
            return (
                <button className={getButtonClassName()} style={buttonStyles} data-testid="btn-primary-dark" {...props}>
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'secondary':
            return (
                <button
                    className={getButtonClassName()}
                    style={buttonOutlineStyles}
                    data-testid="btn-secondary"
                    {...props}
                >
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'secondary-dark':
            return (
                <button
                    className={getButtonClassName()}
                    style={{ ...buttonOutlineStyles, boxShadow: 'inset 0px 0px 0px 1px #1352AB' }}
                    data-testid="btn-secondary-dark"
                    {...props}
                >
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'danger':
            return (
                <button
                    className={getButtonClassName()}
                    style={{ ...buttonStyles, background: '#FFD8D8' }}
                    data-testid="btn-danger"
                    {...props}
                >
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'inverse':
            return (
                <button className={getButtonClassName()} style={buttonStyles} data-testid="btn-inverse" {...props}>
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'inverse-outline':
            return (
                <button
                    className={getButtonClassName()}
                    style={{ ...buttonStyles, boxShadow: 'inset 0px 0px 0px 1px white' }}
                    data-testid="btn-inverse-outline"
                    {...props}
                >
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'navbar':
            return (
                <button
                    className={getButtonClassName()}
                    style={{ ...buttonStyles }}
                    data-testid="btn-navbar"
                    {...props}
                >
                    {getIcon()}
                    {props.children}
                </button>
            );
        case 'navbar-disabled':
            return (
                <button
                    className={getButtonClassName()}
                    style={{ ...buttonStyles }}
                    data-testid="btn-navbar-disabled"
                    {...props}
                >
                    {getIcon()}
                    {props.children}
                </button>
            );
        default:
            return (
                <button className={getButtonClassName()} style={buttonStyles} {...props}>
                    {getIcon()}
                    {props.children}
                </button>
            );
    }
};

export default Button;
